import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserPrivacy from "./pages/userPrivacy";

const Home = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./pages/home")
);

const Privacy = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./pages/privacy")
);

const Terms = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./pages/terms")
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="privacy-user" element={<UserPrivacy />} />
        {/* <Route path="terms-conditions" element={<Terms />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
